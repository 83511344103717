export const API = "http://157.230.102.54:8081/api";
export const allPermissions = [
    "dashboard-view",
    "dropoffs-view",
    "dropoffs-create",
    "orders-view",
    "orders-show",
    "orders-ship",
    "orders-cancel",
    "shipments-view",
    "shipments-create",
    "shipments-show",
    "shipments-edit",
    "shipments-delete",
    "shipments-calldriver",
    "wallets-view",
    "wallets-show",
    "wallets-approve",
    "wallets-reverse",
    "users-view",
    "users-create",
    "users-show",
    "users-edit",
    "users-delete",
    "users-assign-role",
    "users-activate",
    "users-deactivate",
    "categories-view",
    "categories-create",
    "categories-show",
    "categories-edit",
    "categories-delete",
    "products-view",
    "products-create",
    "products-show",
    "products-edit",
    "products-delete",
    "productvariants-view",
    "productvariants-create",
    "productvariants-edit",
    "productvariants-delete",
    "warehouses-view",
    "warehouses-create",
    "warehouses-edit",
    "warehouses-delete",
    "warehouses-activate",
    "warehouses-deactivate",
    "regions-view",
    "regions-create",
    "regions-edit",
    "regions-show",
    "regions-delete",
    "roles-view",
    "roles-create",
    "roles-edit",
    "roles-delete",
    "roles-show-users",
  ];
  