
import ActionButton from "./ActionButton";


const Controls = {

  ActionButton,

};

export default Controls;
