export const tabList = [
  {
    value: "english",
    name: "English",
    shortHand: "en",
    placeHolder: "english name",
    label: "En name",
  },
  {
    value: "amharic",
    name: "Amharic",
    shortHand: "am",
    placeHolder: "amharic name",
    label: "Am name",
  },
];
